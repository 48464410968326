@import "../responsive.mixin";

.form-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 2rem 0;

  @include breakpoint-up("tablet") {
    padding: 2rem;
  }

  @include breakpoint-up("desktop") {
    padding: 3.5rem;
  }

  &.platform {
    margin: 0;
    padding: 0 2rem;

    @include breakpoint-up("desktop") {
      margin-bottom: 2rem;
    }
  }

  .publishing-platforms {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint-up("desktop") {
      flex-direction: row;
    }

    .platform-container {
      display: contents;
    }

    .platform {
      @include breakpoint-up("desktop") {
        flex: 0 1 50%;
      }
    }
  }

  .platform-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    @include breakpoint-up("desktop") {
      height: 12rem;
    }

    .platform-logo {
      display: block;
      max-width: 100%;
      max-height: 8rem;
      margin-bottom: 1.5rem;
    }

    .platform-name {
      font-size: 1.5rem;
      font-weight: 600;
      font-style: normal;
    }
  }
}
