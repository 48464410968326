@import './scss/responsive.mixin.scss';
@import './scss/info-form.scss';
@import './scss/two-card-page.scss';
// Publishing platforms styles
@import './scss/custom-styles/publishing-platforms';

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
body {
  font-size: 1.6rem;
}
h1 {
  font-size: 2.3rem;
  line-height: 1.6;
}

h2 {
  font-size: 1.9rem;
  line-height: 1.6;
}

app-root {
  display: flex;
  flex-direction: row;
  height: 100vh;
  max-height: 100%;
  justify-content: center;
}
app-layout {
  width: 100%;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > * {
    height: 100%;
  }
  router-outlet {
    height: auto;
  }
}

form {
  display: flex;
  flex-direction: column;
}

//chart-styling

.chart-title {
  font-size: 2rem;
  padding: 1rem 0 2rem;
  border-top: 1px solid #000;
  margin: 0;
  font-weight: normal;
}

.mouse-position-tooltip {
  display: block;
  position: fixed;
  font-size: 1.2rem;
  background: rgba(black, 0.7);
  border-radius: 0.3rem;
  color: white;
  top: 2.5rem;
  z-index: 5;
  padding: 0.4rem;
  display: flex;
  &::after {
    content: " ";
    position: absolute;
    top: 120%;
    left: 50%;
    margin-top: -0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: rgba(black, 0.8) transparent transparent transparent;
  }
  .tooltip-square {
    height: 1.2rem;
    width: 1.2rem;
    border: 1px solid white;
    margin-right: 0.5rem;
  }
  .tooltip-text {
    margin: 0 0.5rem;
  }
}

//angular-material-checkbox

.no-scroll mat-sidenav-content {
  overflow: hidden;
}

//angular-material-accordian


.mat-accordion {
  width: 100%;
  margin: 0 10px;

  .mat-expansion-panel-header-title {
    display: flex;
    flex-grow: 1;
    margin-right: 16px;
    flex-basis: 0 !important;
  }

  .mat-expansion-panel-header-description {
    justify-content: space-between;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  div.mat-expansion-panel-body {
    padding: 0 !important;
    display: flex;
    flex-wrap: wrap;
  }

  .mat-content {
    overflow: visible;
  }
}

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: #3f51b5 !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: white !important;
  display: flex;
  align-items: center;
  .mat-icon {
    opacity: 0.6 !important;
    margin-left: 1rem;
  }
}

//forms

.visible-form-field {
  .mdc-text-field {
    overflow: visible !important;
    position: relative;
    z-index: 2;
  }
}

//buttons

.mat-mdc-raised-button>.mat-icon {
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
}

.mat-mdc-raised-button:not(:disabled) {
  padding: 1rem;
}

//mdc-card

.mdc-card{
  padding: 16px !important;
 }

 //angular-material-checkbox

 .mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: 25px;
}

.mat-mdc-checkbox {
  &.select-all-checkbox {
    margin-bottom: 1rem !important;
  }
}
